import { Application } from '@hotwired/stimulus';
import InviewController from './controllers/inview_controller';
import PhotoswipeController from './controllers/photoswipe_controller';
import SelectedTravelHelperController from './controllers/selected_travel_helper_controller';
import ModalController from './controllers/modal_controller';
import ClipboardController from './controllers/clipboard_controller';
import ZenNumbersToHanController from './controllers/zen_numbers_to_han_controller';
import PriceQuantityTotalController from './controllers/price_quantity_total_controller';
import TourArrangementController from './controllers/tour_arrangement_controller';
import TourPricingDocumentController from './controllers/tour_pricing_document_controller';

window.Stimulus = Application.start();
Stimulus.register('inview', InviewController);
Stimulus.register('selected-travel-helper', SelectedTravelHelperController);
Stimulus.register('photoswipe', PhotoswipeController);
Stimulus.register('modal', ModalController);
Stimulus.register('clipboard', ClipboardController);
Stimulus.register('zen-numbers-to-han', ZenNumbersToHanController);
Stimulus.register('price-quantity-total', PriceQuantityTotalController);
Stimulus.register('tour-arrangement', TourArrangementController);
Stimulus.register('tour-pricing-document', TourPricingDocumentController);
