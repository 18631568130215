import { Controller } from '@hotwired/stimulus';

const COCOON_CONTAINER = '.js-tour-arrangement-details';

export default class extends Controller {
  static targets = ['totalAmount', 'sellTotalAmount', 'totalProfit'];

  static outlets = ['price-quantity-total'];

  connect() {
    this.update();
    $(COCOON_CONTAINER)
      .on('cocoon:after-insert', () => this.update())
      .on('cocoon:after-remove', () => this.update());
  }

  disconnect() {
    $(COCOON_CONTAINER)
      .off('cocoon:after-insert')
      .off('cocoon:after-remove');
  }

  update() {
    const totalAmount = this.priceQuantityTotalOutlets.reduce((acc, outlet) => acc + outlet.totalPrice(), 0);
    this.totalAmountTarget.textContent = totalAmount;

    if (this.hasSellTotalAmountTarget) {
      const sellTotalAmount = this.priceQuantityTotalOutlets.reduce((acc, outlet) => acc + outlet.sellTotalPrice(), 0);
      this.sellTotalAmountTarget.textContent = sellTotalAmount;

      if (this.hasTotalProfitTarget) {
        this.totalProfitTarget.textContent = sellTotalAmount - totalAmount;
      }
    }
  }
}
