import { Controller } from '@hotwired/stimulus';
/* global $, _ */

const PRICING_GROUPS = [0, 1, 2, 3];
const HIDDEN_STYLE = 'none';
const COCOON_CONTAINER = '.js-tour-quotation-details';

const parsePrice = (text) => parseInt(String(text).replace(/[^\d]/g, ''), 10) || 0;

export default class extends Controller {
  static targets = ['totalAmount', 'itemPrice'];

  connect() {
    this.setupEventListeners();
    this.recalculateAll();
  }

  disconnect() {
    this.element.removeEventListener('price-quantity-total:updated', this.handlePriceUpdate);
    $(COCOON_CONTAINER)
      .off('cocoon:after-insert')
      .off('cocoon:after-remove');
  }

  setupEventListeners() {
    this.handlePriceUpdate = _.debounce(this.recalculateAll.bind(this), 100);

    $(COCOON_CONTAINER)
      .on('cocoon:after-insert', () => this.handlePriceUpdate())
      .on('cocoon:after-remove', () => this.handlePriceUpdate());

    this.element.addEventListener('price-quantity-total:updated', this.handlePriceUpdate);
  }

  recalculateAll() {
    requestAnimationFrame(() => {
      this.updateGroupSubtotals();
      this.updateTotalAmount();
    });
  }

  updateGroupSubtotals() {
    PRICING_GROUPS.forEach((groupNumber) => {
      const subtotal = this.calculateGroupSubtotal(groupNumber);
      this.updateSubtotalDisplay(groupNumber, subtotal);
    });
  }

  calculateGroupSubtotal(groupNumber) {
    return this.itemPriceTargets
      .filter((element) => {
        const row = element.closest('.nested-fields');
        if (!row || window.getComputedStyle(row).display === HIDDEN_STYLE) return false;

        const hiddenInput = row.querySelector('input[name*="[group_number]"]');
        return hiddenInput && hiddenInput.value === groupNumber.toString();
      })
      .reduce((sum, el) => sum + parsePrice(el.textContent), 0);
  }

  updateSubtotalDisplay(groupNumber, subtotal) {
    const element = this.element.querySelector(
      `.js-group-subtotal[data-group-number="${groupNumber}"]`,
    );
    if (element) {
      element.textContent = subtotal.toLocaleString();
    }
  }

  updateTotalAmount() {
    if (!this.hasTotalAmountTarget) return;

    const subtotals = this.element.querySelectorAll('.js-group-subtotal');
    const total = Array.from(subtotals).reduce(
      (sum, el) => sum + parsePrice(el.textContent),
      0,
    );

    this.totalAmountTarget.textContent = total.toLocaleString();
  }

  detailChanged() {
    this.recalculateAll();
  }
}
